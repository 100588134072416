<template>
  <v-sheet outlined rounded min-height="100%">
    <v-toolbar color="green" class="ma-0" dark flat>
      
      <v-toolbar-title>
        <div>
          <span>Отчеты</span>
        </div>
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
        >
          <v-tab>Балансы</v-tab>
          <v-tab>Транзакции</v-tab>
          <v-tab>История</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div v-if="loading"><Loader /></div>
    <div v-else>
      <v-tabs-items v-model="tab" >
        <v-tab-item class="ma-0 pa-0">
          <RefNavigator 
            :items="filteredCompanies"
            :headers="companiesHeaders"
            :hasSearchTextField="true"
          />
        </v-tab-item>
        <v-tab-item>
          <div class="mt-5">
            <div class="d-flex flex-row justify-start align-center ml-3 mr-3">
              <v-autocomplete
                :items="companies"
                item-value="id"
                item-text="name"
                v-model="selected_company"
                class="mr-4"
                style="max-width: 450px;"
                label="Выберите компанию:"
              ></v-autocomplete>
              <v-text-field
                type="date"
                label="Дата с:"
                v-model="date_from"
                style="max-width: 218px;"
                class="mr-4"
              ></v-text-field>
              <v-text-field
                type="date"
                label="Дата по:"
                v-model="date_to"
                style="max-width: 218px;"
              ></v-text-field>
              <v-btn @click="filterReportOperations" class="ml-auto" color="success" dark>Применить фильтры</v-btn>
            </div>
            <div>
              <RefNavigator 
                :items="filteredOperations"
                :headers="operationsHeaders"
              />
            </div>
          </div>
          <div class="pa-3 d-flex justify-end">
            <v-btn @click="download_operations_report" color="success" dark v-if="!loading">Скачать отчет</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="mt-5">
            <div class="d-flex flex-row justify-start align-center ml-3 mr-3">
              <v-autocomplete
                :items="companies"
                item-value="id"
                item-text="name"
                v-model="history_company"
                class="mr-4"
                style="max-width: 450px;"
                label="Выберите компанию:"
              ></v-autocomplete>
              <v-text-field
                type="date"
                label="Дата с:"
                v-model="history_date_from"
                style="max-width: 218px;"
                class="mr-4"
              ></v-text-field>
              <v-text-field
                type="date"
                label="Дата по:"
                v-model="history_date_to"
                style="max-width: 218px;"
              ></v-text-field>
              <v-btn @click="filterReportHistory" class="ml-auto" color="success" dark>Применить фильтры</v-btn>
            </div>
            <div>
              <RefNavigator 
                :items="history"
                :headers="historyHeaders"
              />
            </div>
          </div>
          <div class="pa-3 d-flex justify-end">
            <v-btn @click="download_history_report" color="success" dark v-if="!loading">Скачать отчет</v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-sheet>
</template>

<script>

import RefNavigator from '../components/RefNavigator.vue'
import Loader from '../components/ui/Loader.vue'
import {mapGetters} from 'vuex'


export default {
  name: 'Reports',
  data: () => ({
    loading: true,
    selected_company: undefined,
    operations: [],
    history: [],
    tab: 0,
    date_from: undefined,
    date_to: undefined,


    history_company: undefined,
    history_date_from: undefined,
    history_date_to: undefined
  }),
  components: {
    RefNavigator, 
    Loader
  },
  async mounted() {
    await this.$store.dispatch('load_companies')
    try {
      this.selected_company = this.filteredCompanies[0].id
      this.history_company = this.filteredCompanies[0].id
    } catch(e) {
      this.selected_company = {}
    }
    
    // Даты
    let date = new Date()
    this.date_to = date.toISOString().substr(0, 10)
    this.history_date_to = this.date_to
    date.setDate(-30)
    this.date_from = date.toISOString().substr(0, 10)
    this.history_date_from = this.date_from

    try {
      this.operations = await this.$store.dispatch('get_report_operations', this.filters)
    } catch(e) {
      console.error(e)
      this.operations = []
    }
    try {
      this.history = await this.$store.dispatch('get_report_history', this.historyFilters)
    } catch(e) {
      console.error(e)
      this.history = []
    }
    this.loading = false
  },
  methods: {
    async download_operations_report() {
      await this.$store.dispatch('download_operations_report', this.filters)
    },
    async download_history_report() {
      await this.$store.dispatch('download_history_report', this.historyFilters)
    },

    async filterReportOperations() {
      this.operations = await this.$store.dispatch('get_report_operations', this.filters) 
    },
    async filterReportHistory() {
      this.history = await this.$store.dispatch('get_report_history', this.historyFilters)
    }
  },
  computed: {
    ...mapGetters(['companies', ]),

    // Компании баланс
    filteredCompanies() {
      
      let arr = this.companies.filter(elem => !elem.deleted)
      arr.map(elem => {
        elem.balance = elem.balance.toFixed(2)
        elem.tariff = elem.tariff.toFixed(2)
      })
      return arr
    },

    companiesHeaders() {
      return [
        {
          text  : '#',
          align : 'start',
          value : 'id'
        },
        {
          text  : 'Название',
          value : 'name'
        },
        {
          text  : 'Баланс',
          value : 'balance'
        }
      ]
    },
    
    // Транзакции
    filteredOperations() {
      let result = this.operations

      result.map(elem => {
        if(elem.type == 'ACCRUAL') {
          elem.type = 'Начисление'
        } else if(elem.type == 'WRITEOFF') {
          elem.type = 'Списание'
          elem.sum = (-1)*elem.sum
        } else if(elem.type == 'CORRECTION') {
          elem.type = 'Изъятие'
          elem.sum = (-1)*elem.sum
        }

        // let date = new Date(elem.date)
        // elem.date_formatted = date.toLocaleDateString()
      })
      // console.log(result)
      return result
    },
    operationsHeaders() {
      return [
        {
            text  : '#',
            align : 'start',
            value : 'id'
          },
          {
            text  : 'Дата',
            value : 'date',
          },
          {
            text  : 'Время',
            value : 'time'
          },
          {
            text  : 'Тип операции',
            value : 'type'
          },
          {
            text  : 'Сумма',
            value : 'sum'
          },
          {
            text : 'Комментарий',
            value : 'comment'
          },
          {
            text : 'Отправитель',
            value : 'sender'
          }
      ] 
    },  

    historyHeaders() {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id'
        },
        {
          text  : 'Дата',
          value : 'date',
        },
        {
          text  : 'Время',
          value : 'time'
        },
        {
          text  : 'Статус',
          value : 'status'
        },
        {
          text  : 'Телефон',
          value : 'phone'
        },
        {
          text  : 'Код',
          value : 'code'
        },
        {
          text  : 'id запроса',
          value : 'request_id'
        },
        {
          text  : 'Ответ GreenSMS',
          value : 'response'
        }
      ]
    },

    filters() {
      return {
        company_id: this.selected_company,
        date_from: this.date_from,
        date_to: this.date_to
      }
    },

    historyFilters() {
      return {
        company_id: this.history_company,
        date_from: this.history_date_from,
        date_to: this.history_date_to
      }
    }


  }
}
</script>